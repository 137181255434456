.personal {
  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;

    @media (max-width: 480px) {
      border-radius: 6px;
      background: linear-gradient(237.44deg, #1DCC95 -5.39%, #4B9FDC 88.22%);
      padding: 10px 8px;
      width: 100%;
      column-gap: 11px;
    }

    &__name {
      display: none;
      @include personalItem();
      font-size: 2.0rem;
      margin-bottom: 17px;

      @media (max-width: 480px) {
        display: block;
        width: 100%;
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 23px 15px 15px;
      width: 100%;
      max-width: 221px;
      border-radius: 6px;
      background: linear-gradient(122.3deg, #1DCC95 20.88%, #4B9FDC 108.45%);

      @media (min-width: 481px) {
        &:hover,
        &:focus {
          .personal-dropdown {
            display: block;
          }

          .personal-list__link {
            box-shadow: 1px 1px 4px 0px #00000040;
          }
        }
      }

      @media (max-width: 480px) {
        flex: 0 0 60px;
        flex-direction: column-reverse;
        background: none;
        width: unset;
        padding: 0;
        gap: 6px;
        height: 60px;
      }

      > span {
        &:not(.icon) {
          @include personalItem();

          @media (max-width: 480px) {
            font-size: 1.7rem;
            margin-bottom: 7px;
          }
        }

        &.icon {
          color: white;
          font-size: 2.0rem;
        }
      }
    }

    &__link {
      position: absolute;
      font-size: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 6px;

      @media (min-width: 481px) {
      &:hover,
      &:focus {
        box-shadow: 1px 1px 4px 0px #00000040;
      }
      }
    }

    &__close--mobile {
      display: none;
      font-size: 0;

      @media (max-width: 480px) {
        position: absolute;
        justify-content: center;
        align-items: center;
        top: -43px;
        right: 0;
        width: 43px;
        height: 43px;

        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 21px;
          height: 1px;
          background-color: white;
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(45deg);
        }

        &:before {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &-dropdown {
    display: none;
    bottom: 0;
    width: 210px;
    padding: 20px 40px 0;
    position: absolute;
    left: 20px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 #00000040;
    transform: translate(0, 98%);
    z-index: 1000;

    @media (max-width: 480px) {
      &:after {
        content: '';
        background-color: rgba(249, 249, 249, 1);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 60px;
      }
    }

    @media (max-width: 480px) {
      display: block;
      border-radius: 0;
      position: fixed;
      bottom: 0;
      width: 100vw;
      transform: translateY(100%);
      left: 0;
      right: 0;
      transition: 0.3s ease-in-out all;
    }

    &.active {
      display: block;

      @media (max-width: 480px) {
        .personal-list__close--mobile {
          display: flex;
        }

        transform: translateY(0);

        + .personal-dropdown-overlay {
          display: block;
        }
      }
    }

    &__title {
      display: flex;
      align-items: flex-start;
      gap: 11px;
      @include personalDropdownTitle();

      @media (max-width: 480px) {
        padding-bottom: 15px;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 60px;
          width: 100%;
          height: 1px;
          background-color: $vnf-neutral-100;
        }
      }

      .icon {
        position: absolute;
        left: 11px;

        &:before {
          font-size: 2.0rem;
          color: $vnf-blue-a500;
        }
      }

      .title {
        position: relative;

        &:after {
          position: absolute;
          left: 0;
          top: 21px;
          font-size: 0.55rem;
          color: $vnf-green-b300;
          content: "\e91e";
          @include icon-font();
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      height: 40px;

      > a {
        height: auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.2s ease-in-out all;

        &:hover,
        &:focus {
          color: $vnf-blue-a500;
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 25px 0 8px;
      color: #143477;
      font-size: 1.4rem;
      line-height: 16.41px;

      &.slider-active {
        .swiper-container {
          padding-bottom: 40px;
        }
      }

      .swiper-container {
        height: auto;
        max-width: 100%;
        overflow: hidden;

        &::part(container) {
          max-height: 280px;
          position: static;
          margin-bottom: 10px;
        }

        swiper-slide {
          display: flex;
          height: 40px !important;
          align-items: center;
        }

        &::part(button-prev) {
          top: unset;
          bottom: 0px;
          transform: rotate(-90deg) translateY(-100%);
          left: 50%;
          width: 48px;
          height: 48px;

          &:before {
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            background: #FFFFFF;

            @media (max-width: 480px) {
              background: #F9F9F9;
            }
          }

          &:after {
            position: absolute;
          }
        }

        &::part(button-next) {
          top: unset;
          bottom: 0px;
          transform: rotate(90deg) translateY(-100%);
          right: 50%;
          width: 48px;
          height: 48px;

          &:before {
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            background: #FFFFFF;

            @media (max-width: 480px) {
              background: #F9F9F9;
            }
          }

          &:after {
            position: absolute;
          }
        }
      }
    }

    &-link {
      font-size: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 43px;
      height: 43px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 13px;
        background-color: $vnf-blue-a400;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 13px;
        height: 1px;
        background-color: $vnf-blue-a400;
      }
    }
  }

  &-dropdown-overlay {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.links {
  &-add,
  &-modify {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__item {
      a {
        display: flex;
        gap: 14px;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 400;

        &[rel="external"] {
          &:before {
            content: url('/assets/images/icons/external-link.svg');
          }
        }
      }
    }

    &__form {
      position: relative;
      display: flex;
      background: white;
      flex-direction: column;
      padding: 40px 20px 30px;
      gap: 20px;
      width: 100%;
    }

    &__close-form {
      position: absolute;
      right: 50px;
      top: 10px;
      font-size: 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 1px;
        background-color: $vnf-grey-b3;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__submit {
      position: absolute;
      right: 20px;
      top: 8px;
      font-size: 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        width: 18px;
        height: 8px;
        border-bottom: 1px solid $vnf-grey-b3;
        border-left: 1px solid $vnf-grey-b3;
        transform: rotate(-45deg);
      }
    }
  }

  &-add {
    &__form {
      margin-top: 20px;
    }
  }

  &-modify {
    &__item {
      display: flex;
      flex-wrap: wrap;
      gap: 22px;

      a {
        margin-right: auto;
      }
    }

    &__edit {
      background-image: url('/assets/images/icons/modify.svg');

      &.active {
        background-image: url('/assets/images/icons/modify-blue.svg');
      }
    }

    &__delete {
      background-image: url('/assets/images/icons/trash.svg');
    }

    &__edit,
    &__delete {
      width: 15px;
      height: 15px;
      font-size: 0;
      background-repeat: no-repeat;
    }
  }

  &-dialog {
    h3 {
      @include titleFormStep();
      text-align: center;
      margin-bottom: 20px;

      &:after {
        left: 50%;
        transform: translateX(-50%), rotate(-11deg);
      }
    }

    p {
      font-size: 1.6rem;
      font-family: "Roboto", sans-serif;
      line-height: 1.8rem;
      letter-spacing: 0.7px;
      text-align: center;
      margin-bottom: 35px;
    }

    .mat-mdc-tab-body {
      padding: 15px 30px 50px;
    }

    .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      font-size: 16px;
      color: $vnf-grey-b3;
      letter-spacing: 0.4px;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label {
      color: $vnf-grey-b3;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
      border-color: #3155A429;
    }
  }
}

.links-add__form {
  background-color: #F9F9F9;

  .mdc-text-field__input {
    font-size: 14px !important;
    color: #3155A4 !important;
  }

  .mat-mdc-form-field-infix {
    display: flex;
  }
}

app-dialog-mes-liens {
  .mat-mdc-dialog-close-button {
    display: block;
    margin-left: auto;
  }

  .links-dialog {
    h3 {
      color: #5E92FF;
    }
  }

  .mat-mdc-tab-list {
    background: #5E92FF;
  }

  .mat-mdc-dialog-header,
  .mat-mdc-dialog-content {
    background-color: #EEEEEE;
  }

  mat-tab-body {
    background-color: #FFFFFF;
  }
}
